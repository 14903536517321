const privacyHeading = {
  title: "Privacy Policy",
  content:
    'This Privacy Policy (hereinafter referred to as the "Privacy Policy") is which is referenced and fully incorporated in and forms part of the Terms of Use Agreements and is entered into by and between VantageSMS, a Division of Bizdivvy Inc., an Ontario, Canada corporation, with offices at 7030 Woodbine Avenue, Markham, Ontario, Canada, L3R 6G2 (hereinafter referred to as "VantageSMS", "we", "us" or "our") and the individual, corporation, applicable party or other entity agreeing to the terms and conditions of the Terms of Use, the Acceptable Use Agreement and the Privacy Policy (hereinafter referred to as the "Customer", "User", "he", "his" "you" or "your"). If the Customer does not agree with the Privacy Policy, then  the Customer must not use the Services.',
}

const lastUpdate = {
  // date: "July 25 2018",
  date: "May 20 2019",
}

const privacyArray = [
  {
    title: "Why does VantageSMS have a Privacy Policy",
    content: [
      {
        text:
          "We want our Customers to know what Customer Data (as defined in the Terms of Use) that the Customer or Vantage on the Customer’s behalf,  transfers, uploads, downloads, stores, processes, uses, distributes, sends, transmits, displays, makes available or otherwise communicates to, on, with and/or through the Services with the Customer's recipients and that we have available to us from the Customer through the Customer's use of the Services including without limitation, how we may use that Data (as defined in the Terms of Use), including Customer Data to assist us in providing the Customer with a better and more useful VantageSMS experience in using the Services.",
      },
      {
        text:
          "By using the Services, the Customer acknowledges, agrees and consents to the use of the Customer Data, by VantageSMS as set out in the Terms of Use Agreements.",
      },
      {
        text:
          "VantageSMS encourages the Customer to read Terms of Use Agreements, which include and incorporate the, the Acceptable Use Agreement and the Privacy Policy. If the Customer does not agree to any of the Term of Use Agreements, the Customer must not use the Services.",
      },
      {
        text:
          'The Customer understands, acknowledges, and agrees that the Privacy Policy and the Terms of Use Agreements apply only to the use by the Customer of the Services. During the course of the Customer\'s use of the Services, the Customer will use the services of VantageSMS service providers, without limitation, communication and telecom service providers, payment processing providers, webhosting providers, information technology and related infrastructure providers and email service providers (hereinafter collectively referred to as "third party service providers") that allow VantageSMS to provide the Customer with the Services. VantageSMS is in no way responsible for the use terms and conditions and privacy policy of the third party service providers.',
      },
      {
        text:
          "VantageSMS requires the Customer to provide VantageSMS and the Customer  acknowledges, consents to and agrees to voluntarily provide VantageSMS with Customer Data. The Customer agrees to update any Customer Data that is incorrect or has been changed within five (5) days of such change.  In addition, the Customer acknowledges, consents to and agrees to VantageSMS collection of other information from the Customer's use of the Services  through a number of sources and technologies . One of the primary sources for the collection of such other information is the use of cookies and other technologies that record information about the use of the Services and Software. Such other information that VantageSMS may collect includes without limitation:",
        content: [
          {
            text:
              "Browser and device data such as IP address, device type, operating system and internet browser type, screen resolution, operating system name and version, device manufacturer and model, language, plug-ins, add-ons and the version of our Services you or your recipients may be using;",
          },
          {
            text:
              "Services transaction data related to Customer's Data that the Customer or Vantage on the Customer’s behalf,  transfers, uploads, downloads, stores, processes, uses, distributes, sends, transmits, displays, makes available or otherwise communicates to, on, with and/or through the Services including any and all email, text, SMS messages and chats that the Customer or Vantage on the Customer’s behalf,  distributes, sends, transmits, displays, makes available or otherwise communicates to, on, with and/or through the Services, amounts billed and invoiced and charged to the Customer's credit card, date of such billing, invoice and charge, and payment and date made by the Customer;",
          },
          {
            text:
              "Cookie and information tracking technology information such as time spent on the Services, Services pages visited, and other communication, use and traffic information related to the Customer's use of the Services;",
          },
          {
            text:
              "Data including Customer Data to help VantageSMS analyze Service usage patterns, location positions, and other indicia to assist VantageSMS to enhance and better the VantageSMS Customer user experience.",
          },
        ],
      },
    ],
  },
  {
    title: "Customer Consent",
    content: [
      {
        text:
          "The Customer acknowledges, consents to and agrees that the Customer Data, is the sole responsibility of the Customer and the Customer determines what Customer Data he  or Vantage on the Customer’s behalf,  transfers, uploads, downloads, stores, processes, uses, distributes, sends, transmits, displays, makes available or otherwise communicates to, on, with and/or through the Services.",
      },
    ],
  },
  {
    title: "What do we do with the Data including the Customer Data we collect",
    content: [
      {
        text:
          "The Customer acknowledges, consents to and agrees that VantageSMS and as applicable, its third party service providers, may use, disclose and otherwise deal with the  Data, including Customer Data as follows:",
        content: [
          {
            text:
              "To provide to the Customer the Services and matters ancillary thereto which the Customer has subscribed for,  in accordance with the Terms of Use Agreements;",
          },
          {
            text:
              "To provide Data including Customer Data to the credit card processing service provider and any bank as may be applicable for billing, invoice and payment purposes;",
          },
          {
            text:
              "To anticipate and resolve problems, or other technical issues or questions with or about the Service by or for the Customer;",
          },
          {
            text:
              "For verification and authentication purposes in regards to registration, and requests or changes to any Customer Data and/or Data;",
          },
          {
            text:
              "For any other purposes as set out in the Terms of Use Agreements;",
          },
          {
            text:
              "To exercise, establish and/or enforce any and all of the legal and other rights and remedies in or related to the provision of the Services, the Terms of Use Agreements and any other legal and other rights  by VantageSMS, its affiliates, subsidiaries, contractors, licensors, any applicable third parties, and all of the aforementioned parties respective employees, officers, directors, representatives, agents and/or shareholders.",
          },
          {
            text:
              "To respond to a subpoena, court order or any other legal process or demand;",
          },
          {
            text:
              "To allow VantageSMS, its affiliates, subsidiaries, contractors, licensors, any applicable third parties, and/or Vantages SMS's other Customers and all of the aforementioned parties respective employees, officers, directors, representatives, agents and/or shareholders to exercise their legal rights, protect and/or defend against legal claims or actions;",
          },
          {
            text:
              "To permit such use and/ or disclosure that VantageSMS reasonably believes is: (a) necessary in order to investigate, prevent, or take any action regarding suspected illegal activities, detection, prevention and dealing with security and fraud issues, technical issues, other serious and significant issues, or situations involving potential threats to the property, rights, title and interests and physical safety of any person, including without limitation, VantageSMS, other Customers of the Services, Customers recipients, any third parties and all of the aforementioned parties respective subsidiaries, affiliates, successors, and assigns and their respective employees, agents, directors, officers and/or shareholders, Services, Software, website, apps, linked websites and/or platforms; or (b) required by law;",
          },
          {
            text:
              "In the event VantageSMS is involved in a business transfer, merger, acquisition, consolidation, divestiture or insolvency and pursuant to the business transfer, merger, acquisition, consolidation, divestiture or insolvency, VantageSMS is and shall be permitted to transfer Data, including Customer Data;",
          },
          {
            text:
              "To enhance the Services Customer experience and to help VantageSMS determine what our Customers preferences are in order to improve the Services and potentially develop new and better Services enhancements for our Customers;",
          },
          {
            text:
              "To use in any way that VantageSMS may deem appropriate, any Customer Data that is aggregated and non-identifiable;",
          },
          {
            text:
              "Use Customer Data to respond to the Customer's contact with VantageSMS for various reasons, including without limitation, accounting, billing, technical and support requests; and,",
          },
          {
            text:
              "Use Customer Data for a range of different purposes, including without limitation, developing business intelligence, helping in future business decisions, bettering our Services operations and enhancing our Customer support and response.",
          },
        ],
      },
    ],
  },
  {
    title: "Application of Privacy Policy",
    content: [
      {
        text:
          "The Privacy Policy applies to the Services provided by VantageSMS to its Customers.",
      },
      {
        text:
          "The Privacy Policy does not apply to any services offered by third party service providers that may be used by VantageSMS and any of its Customers who may be involved in communications by, between and among VantageSMS, its Customers and the Customers recipients.",
      },
    ],
  },
  {
    title: "Information the Customer provides to VantageSMS",
    content: [
      {
        text:
          "When an individual, corporation, applicable party or other entity  subscribes to the Services, he consents to the provision of and voluntarily provides personal, corporate, applicable party or other entity profile information including name, address, telephone number, photos, personal, corporate and organizational preferences, etc.; the Customer consents to the availability of such Customer Data, to VantageSMS and to the Customer's recipients. The Customer acknowledges and agrees that VantageSMS is not responsible for any Customer Data that the Customer transfers, uploads, downloads, stores, processes, uses, distributes, sends, transmits, displays, makes available or otherwise communicates to, on, with and/or through the Services with the Customer's recipients.",
      },
      {
        text:
          "The Customer acknowledges and agrees that the Customer has the ability to appoint  one or more individuals to act as Administrators or agents and those additional individuals have the ability to view, change, delete and work with the Customer Data within the Customer's Services account; and the,",
      },
      {
        text:
          "Customer hereby acknowledges, consents to and agrees to be responsible for any and all activity within the Customer Services account.",
      },
    ],
  },
  {
    title: "Control of the Customer Data",
    content: [
      {
        text:
          "The Services gives the Customer the ability to transfer, upload, download, store, process, use, distribute, send, transmit, display, make available or otherwise communicate to, on, with and/or through the Services with the Customer's recipients; the Customer acknowledges, consents to and agrees the Customer will not be able to control or otherwise modify, change or delete the Customer Data that the Customer transfers, uploads, downloads, stores, processes, uses, distributes, sends, transmits, displays, makes available or otherwise communicates to, on, with and/or through the Services with the Customer's recipients.",
      },
    ],
  },
  {
    title: "Privacy Policy Enforcement",
    content: [
      {
        text:
          "VantageSMS periodically reviews its Privacy Policy and will make reasonable efforts to comply with regulatory and legal requirements; by subscribing for the Services and using the Services provided thereby, the Customer hereby consents to the application of the Privacy Policy as set out herein; in the event of any conflict between the Privacy Policy and the regulatory and legal requirements of any local jurisdiction, the Customer and VantageSMS agree that the Privacy Policy shall apply to the extent that the law permits;",
      },
    ],
  },
  {
    title: "Amendments or Changes to the ANM Privacy Policy",
    content: [
      {
        text:
          "The ANM Privacy Policy may be changed from time to time. Any amendments or changes to the Privacy Policy will be posted on Vantage website.",
      },
    ],
  },
  {
    title: "Miscellaneous Matters",
    content: [
      {
        text:
          "The Privacy Policy is intended to be and is in addition to the terms, conditions, obligations and use terms set out in the Terms of Use Agreements is not intended in an way limit the required and acceptable use of the Services by the Customer set out in the Terms of Use, the Acceptable Use Agreement and the Terms of Use Agreements. The definitions set out in the Terms of Use and the Acceptable Use Agreement shall apply to and form part of the Privacy Agreement and such definitions are referenced and fully incorporated in and to the Privacy Agreement and are ancillary thereto.",
      },
      // {
      //   text:
      //     'The provisions of the Privacy Policy shall be read with all grammatical   changes thereby rendered necessary.  The division of the Privacy Policy into Sections, Subsections and other subdivisions and the insertion of headings, if applicable, are for convenience of reference only and shall not affect or be utilized in the construction or interpretation thereof and shall not in any way whatsoever define, limit or enlarge the scope or meaning of the Privacy Policy or any part thereof. Whenever the words “include,” “includes” or “including” are used in the Privacy Policy, they shall be deemed to be followed by the words “without limitation.”  Any regulation, statute or other applicable law defined or referred to in the Privacy Policy means such regulation, statute or other applicable law as amended, unless otherwise specifically provided therein. References to “hereof”, “herein”, “hereto”, "hereunder", "hereby" and like references refer to the Privacy Policy and not to any particular Section, subsection, clause, subclause, paragraph or other subdivision or portion of the Privacy Policy and include amendments thereto made from time to time, any agreement which is supplementary to or in amendment or confirmation of the Privacy Policy. Where the context so requires in the Privacy Policy shall be read with all changes in gender and number required by context. ',
      // },
      {
        text:
          "The Customer hereby agrees that all of the provisions of the General miscellaneous terms, Section 15, of the Terms of Use are fully incorporated into this Privacy Policy and shall and do form part of this Privacy Policy. ",
      },
      {
        text:
          "The Customer acknowledges, agrees and consents to receiving electronic communications from VantageSMS related to the Services.",
      },
      {
        text:
          "If any Customer has any questions or comments regarding the Privacy Policy, please contact  VantageSMS at: Support@VantageSMS.com",
      },
    ],
  },
]

module.exports = {
  heading: privacyHeading,
  body: privacyArray,
  lastUpdate: lastUpdate,
}
